<footer class="page-footer grey darken-1">
    <div class="container">
        <div class="row">
            <div class="col l6 s12">
                <div class="row" id="marco_powered_container">
                    <a [href]="dashboardURL"><img class="col" src="assets/img/marco.svg"></a>
                    <a [href]="dashboardURL"><h5 class="white-text col">{{ 'footer.poweredByMarco' | translate }}</h5>
                    </a>
                </div>
                <p class="grey-text text-lighten-4">{{ 'footer.marcoMessage' | translate }}</p>

                <ul>
                    <li><a class="white-text" href="mailto:info@finboot.com">{{ 'footer.contact' | translate }}</a></li>
                </ul>
            </div>
            <div class="col l4 s12 right">
                <div class="row" id="ue_container">
                    <a [href]="dashboardURL"><img class="col" src="assets/img/ue.png"></a>
                </div>
                <p class="grey-text text-lighten-4">{{ 'footer.fundingMessage' | translate }}</p>
            </div>
        </div>
    </div>
    <div class="footer-copyright">
        <div class="container">
            <a class="grey-text text-lighten-3" href="https://finboot.com">&copy; Finboot LTD
                - {{ date.getFullYear() }}</a>
            <a [href]="dashboardURL" class="grey-text text-lighten-3 right">&copy; Marco Blockchain Platform</a>
        </div>
    </div>
</footer>
