import {Injectable} from '@angular/core';
import {BlRequestService} from '../../support/blRequest.service';
import {Observable} from 'rxjs';

@Injectable()
export class BlSampleMixTypeService {

    constructor(private  blRequestService: BlRequestService) {
    }

    basePath = '/sample-mix-types';

    list(): Observable<any[]> {
        return this.blRequestService.get(this.basePath);
    }
}
