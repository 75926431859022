<ngx-spinner size="medium" type="ball-clip-rotate">
    <p style="font-size: 35px; color: white">
        {{ 'common.loading' | translate }}
    </p>
</ngx-spinner>

<app-topbar></app-topbar>

<alerts></alerts>

<app-maincontent></app-maincontent>

<app-footer></app-footer>
