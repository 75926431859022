import {enableProdMode} from '@angular/core';
import {environment} from './environments/environment';
import {AppComponent} from './app/app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {bootstrapApplication} from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import {appConfig} from "./app/app.config";

if (environment.production) {
    enableProdMode();
}

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

bootstrapApplication(AppComponent, appConfig);
