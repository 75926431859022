import {Component} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {SampleListKey, UserListKey} from './support/constants';
import {FooterComponent} from './footer/footer.component';
import {TopbarComponent} from './topbar/topbar.component';
import {NgxSpinnerComponent} from 'ngx-spinner';
import {MainContentComponent} from "./maincontent/main-content.component";
import {AlertsComponent} from "./components/shoelace/alert-component/alerts.component";

declare let gtag: Function;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    standalone: true,
    imports: [NgxSpinnerComponent, TopbarComponent, FooterComponent, TranslateModule, MainContentComponent, AlertsComponent]
})
export class AppComponent {
    title = 'app';

    constructor(
        private router: Router,
        translate: TranslateService
    ) {
        translate.addLangs(['es', 'en']);
        translate.setDefaultLang('es');
        translate.use('es');
        localStorage.setItem(SampleListKey, '10')
        localStorage.setItem(UserListKey, '10')
        this.registerGA();
    }

    registerGA() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'UA-178738945-1',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
            }
        });
    }
}
