import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'capitalizeFirst',
    standalone: true,
    pure: true
})
export class CapitalizeFirstPipe implements PipeTransform {

    transform(value: string): string {
        if (!value) return value;
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

}