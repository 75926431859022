<div class="toast-container">
    @for (alert of alerts; track alert.id) {
        <sl-alert #alert duration="5000" open closable variant="danger" (onClose)="removeAlert(alert.id)">
            <sl-icon name="exclamation-octagon" slot="icon"></sl-icon>
            @if (alert.heading) {
                <strong>{{ alert.heading }}</strong>
            }
            {{ alert.message }}
        </sl-alert>
    }
</div>
