import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class HttpCancelService {
    private cancelPendingRequests$ = new Subject<void>();

    // Observable to get the cancel signal
    getCancelPendingRequests$() {
        return this.cancelPendingRequests$.asObservable();
    }

    // Method to trigger the cancel signal
    cancelPendingRequests() {
        this.cancelPendingRequests$.next();
    }
}
