import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';

@Component({
    selector: 'app-maincontent',
    templateUrl: './maincontent.component.html',
    styleUrls: ['./maincontent.component.css'],
    standalone: true,
    imports: [RouterOutlet]
})
export class MainContentComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
