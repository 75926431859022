import {HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {inject} from '@angular/core';
import {Router} from '@angular/router';
import LocalManager from "../support/localManager";
import {HttpCancelService} from "../services/HttpCancelService";

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const router = inject(Router);
    const httpCancelService = inject(HttpCancelService);
    const localManager = inject(LocalManager)

    return next(req).pipe(
        takeUntil(httpCancelService.getCancelPendingRequests$()), // Cancel ongoing requests
        catchError((err: HttpErrorResponse) => {
            if (err.error.message === 'User has not been invited to the application') {
                localManager.setActiveSession(null);
                httpCancelService.cancelPendingRequests();
                router.navigate(['no-access']);
                return throwError('User has not been invited to the application');
            }

            if (err.status === 401) {
                localStorage.setItem('nextUrl', router.url);
                httpCancelService.cancelPendingRequests();
                router.navigate(['auth/sign-in']);
                return throwError('Unauthorized access - redirecting to sign-in');
            }

            return throwError(err);
        })
    );
};
