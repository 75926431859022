import {Component, OnInit} from '@angular/core';
import {environment} from "../../environments/environment";
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css'],
    standalone: true,
    imports: [TranslateModule]
})
export class FooterComponent implements OnInit {

    dashboardURL: string;
    date: Date;

    constructor() {
    }

    ngOnInit() {
        this.dashboardURL = environment.dashboardServiceUrl;
        this.date = new Date();
    }

}
