import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {PrincipalAuthService} from "../services/principal-auth.service";
import {map} from "rxjs/operators";

export const authGuard: CanActivateFn = (route, state) => {
    const authService = inject(PrincipalAuthService);
    const router = inject(Router);

    return authService.isLoggedIn().pipe(
        map(isLoggedIn => {
            if (isLoggedIn) {
                return true;
            } else {
                localStorage.setItem('nextUrl', state.url); // Store the current URL
                router.navigate(['auth/sign-in']); // Redirect to sign-in page
                return false;
            }
        })
    );
};
