import {Component, CUSTOM_ELEMENTS_SCHEMA, OnInit} from '@angular/core';
import {PrincipalAuthService} from '../services/principal-auth.service';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {TranslateModule} from '@ngx-translate/core';
import {TopbarUserButtonComponent} from './topbar-user-button/topbar-user-button.component';
import {toSignal} from "@angular/core/rxjs-interop";


@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
    standalone: true,
    imports: [RouterLink, TopbarUserButtonComponent, TranslateModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TopbarComponent implements OnInit {

    usersPath = '/users';
    certificatesPath = '/certificates';
    queryParams;
    isLoggedIn = toSignal(this.principalAuthService.isLoggedIn())

    constructor(private principalAuthService: PrincipalAuthService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        const workspaceId = this.route.snapshot.queryParamMap.get('workspaceId');
        this.queryParams = {workspaceId: workspaceId};
    }

    openManual() {
        const windowReference = window.open();
        windowReference.location.href = 'https://storage.googleapis.com/public-fb-app-marcoapps-prod/blocklabs/doc/manual.pdf';
        return windowReference;
    }


}
